* {
  margin: 0;
}

#root {
  text-align: center;
  
  /*background-image: url('../images/des.jpg');*/
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  
  color: hsl(0, 0%, 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 360px;
}
/*StylesStart*/
/*input {
  
}

*/
.header {
  background-color: hsla(260, 100%, 10%, 0.85);
  flex: 1 1 10%;
  display: flex;
  max-height: 80px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
}

.header .music-rs {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
    unicode-bidi: isolate;
}

/*.header > h1 {
  padding: 0.5% 0;
}*/

.main {
  flex: 8 1 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 6vh 3vh;
  row-gap: 5vh;
}

.footer {
  flex: 1 1 10%;
  min-height: 60px;
  background-color: hsla(0, 100%, 3%, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  /*min-height: 13vh;*/
}

.footer-list {
  /*min-height: 10vh;*/
  display: flex;
  justify-content: space-around;
  width: 90%;
  /*gap: 1rem 2rem;*/
  font-size: 0.9rem;
}

.footer-list * {
  /*margin-left: 1rem;*/
  
}

.heading-one {
  color: hsla(260, 100%, 30%, 1);
  font-size: 2.2em;
  background-color: white;
  height: 1.1em;
}

search {
  
}

.searchBar {
  /*min-height: 100px;
  position: relative;
  top: 35%;*/
  /*padding: 5% 0 3%;*/
}

.searchBar *{
  display: block;
  margin: auto;
}

.searchBar > .input {
  line-height: 1.9;
  min-width: 180px;
  width: 22vw;
  outline-color: hsla(260, 100%, 30%, 1);
  font-size: 1rem;
  text-align: center;
}

.button {
  background-color: hsla(270, 100%, 30%, 1);
  border-radius: 10px;
  border-style: none;
  line-height: 1.5;
  color: inherit;
  padding: .1rem .9rem;
  font-size: .9rem;
  margin-top: 2vh;
}

.results-playlist {
  display: flex;
  flex-wrap: wrap;
  /*padding: 1% 2% 2%;*/
  column-gap: 6%;
  row-gap: 1.5rem;
  align-items: start;
  justify-content: center;
  position: relative;
  
    * {
      z-index: 4;
    }
}

.results, .playlist{
  /*margin: 0 3% 0;*/
  flex: 1 1 120px;
  background-color: hsla(260, 100%, 9%, 0.85);
  border-width: 0px 4px;
  border-style: solid;
  border-color: hsla(260, 100%, 2%, 0.8);
  padding: 2% 3%;
  /*max-height: 70vh;
  overflow-y: scroll;
  overscroll-behavior: contain;
  scrollbar-color: hsla(0, 0%, 0%, 0) hsla(0, 0%, 100%, 0);*/
  max-width: 550px;
  word-break: break-word;
}

.results {
  min-height: 50vh;
  align-self: stretch;
}

.results > .heading, .playlist > .heading {
  border: none;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.2);
  outline: none;
  font-weight: bold;
  font-size: 1.4rem;
  display: block;
  margin: 0 0 7%;
  text-align: left;
  padding: 0 0 1%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  line-height: 1.7rem;
  color: inherit;
}

.results > .heading {
  border: none;
  border-bottom: hidden;
}

.playlist > .heading:focus {
  border-bottom: 1.5px solid hsla(260, 100%, 2%, 0.8);
}

.results-playlist:hover, .results-playlist:focus {
  .music-bars span {
    animation-play-state: paused;
  }
}

.track{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  padding: 5px 0px 8px;
}

.track .artist_album {
  color: hsla(0, 0%, 100%, 0.5);
}

.track_button {
  border: none;
  background-color: inherit;
  box-sizing: content-box;
  min-width: 1rem;
  font-size: 1.5rem;
  color: inherit;
}

.music-bars {
  /*background-color: white;*/
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1%;
  
    span {
      width: 1%;
      height: 40%;
      background-color: green;
      animation: bounce 2.2s ease infinite alternate;
      background-color: color-mix(in hsl shorter hue, hsla(260, 100%, 9%, 0.85) 70%, hsla(260, 100%, 30%, 1) 30%);
      
      &:nth-of-type(1) {
        animation-delay: -2.2s; /* Start at the end of animation */
        background-color: color-mix(in hsl shorter hue, hsla(260, 100%, 5%, 1) 90%, red 10%);
        height: 20%;
      }
      &:nth-of-type(3) {
        animation-delay: -3.7s; /* Start mid-way of return of animation */
        background-color: color-mix(in hsl shorter hue, hsl(240, 50%, 30%) 70%, hsl(240, 50%, 30%) 30%);
        height: 20%;
      }
      &:nth-of-type(5) {
        animation-delay: -2.7s; /* Start mid-way of return of animation */
        background-color: color-mix(in hsl shorter hue, hsl(240, 50%, 30%) 70%, hsl(240, 50%, 30%) 30%);
        height: 20%;
      }
      &:nth-of-type(7) {
        animation-delay: -1.5s; /* Start mid-way of return of animation */
        background-color: color-mix(in hsl shorter hue, hsla(260, 100%, 5%, 1) 90%, red 10%);
        height: 20%;
      }
    }
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3); /* start by scaling to 30% */
  }

  30% {
    transform: scaleY(1); /* scale up to 100% */
  }

  60% {
    transform: scaleY(0.5); /* scale down to 50% */
  }

  80% {
    transform: scaleY(1.3); /* scale up to 75% */
  }

  100% {
    transform: scaleY(0.6); /* scale down to 60% */
  }
}

/*.trackBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}*/

/*.trackSection {
  flex-grow: 1;
}*/

@media only screen and (max-width: 505px) {
    .heading-one {
      height: 2.1em;
      line-height: 1.2;
    }

    .main {
      padding: 4vh 3vw
    }

    .results-playlist {
      column-gap: 3%;
    }
    
    .music-bars {
      display: none;
    }
}

/*StylesEnd*/
/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
